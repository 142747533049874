<template>
<div class="q-banner">
  <img src="@/assets/img/quotation.png">
</div>
</template>

<script>
export default {
  name: "quotation"
}
</script>

<style scoped>

</style>